export const updateItemById = (array, updatedItem) => {
  const { length } = array;
  for (let i = 0; i < length; i++) {
    if (array[i].id === updatedItem.id) {
      array.splice(i, 1, updatedItem);
      break;
    }
  }
  return array;
};

export const removeItemById = (array, id) => {
  const { length } = array;
  for (let i = 0; i < length; i++) {
    if (array[i].id === id) {
      array.splice(i, 1);
      break;
    }
  }
  return array;
};
