import http from './http';

const endpoint = 'api/time-tracking';

const timeTrackingService = {
  model: 'timeTracking',

  getEntries: params => http.get(endpoint, { params }),

  createMultiple: payload => http.post(`${endpoint}/save-multiple`, payload),
};

export default timeTrackingService;
