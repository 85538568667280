<template>
  <v-container fluid class="pa-0">
    <h1 class="text-h6 px-3 pt-3">
      {{ $t('time_tracking.general.page_title') }}
    </h1>
    <app-time-tracking-table/>
  </v-container>
</template>

<script>
import TimeTrackingTable from './TimeTrackingTable.vue';

export default {
  name: 'TimeTrackingWrapper',

  components: {
    appTimeTrackingTable: TimeTrackingTable,
  },
};
</script>
