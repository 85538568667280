<template>
  <BaseLoadingOverlay :loading="isLoading">
    <v-row dense>
      <v-col cols="12" sm="4" style="padding: 12px 12px 0 12px">
        <BaseAutocomplete
          v-if="$isAdmin()"
          v-model="selectedUser"
          :label="$t('time_tracking.labels.show_another_employee')"
          :search-function="userSearchFunction"
          clearable
          item-text="full_name"
          @input="setUser"
        />
      </v-col>
      <template v-if="items.length">
        <v-col cols="12" sm="4" class="d-flex align-center justify-center">
          <v-btn
            icon
            @click="getPreviousWorkMonth"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <span class="text-h5 text-center" style="font-size: 18px !important; min-width: 120px">
          {{ currentMonth }}
        </span>
          <v-btn
            icon
            @click="getNextWorkMonth"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-col>
        <v-col v-if="$vuetify.breakpoint.lgAndUp" cols="12" sm="4" class="d-flex align-center justify-end">
          <v-btn
            v-show="currentMonthMiddle < dateFrom || currentMonthMiddle > dateTo"
            color="primary"
            class="mx-3"
            @click="getCurrentWorkMonth"
          >
            {{ $t('time_tracking.labels.show_current_month') }}
          </v-btn>
        </v-col>
      </template>
    </v-row>

    <v-simple-table v-if="items.length">
      <thead>
        <tr>
          <th class="text-left">{{ $t('sprint') }}</th>
          <th
            v-for="header in headers"
            :key="header.text"
            :style="{'background': getBackgroundColor(header)}"
            :class="{'clickable': !selectedUser}"
            class="month-column text-center background-transition"
            @click="openForm(header.text, null)"
          >
            {{ header.weekdayStr }}<br>
            {{ header.day }}
          </th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="item in items" :key="item.sprint_id" class="no-hover-effect">
          <td :class="[{'border-l': $vuetify.breakpoint.mdAndDown}, 'sprint-title']">
            <div v-if="item.sprint_status === 'closed'" class="closed-sprint-icon">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" color="error">mdi-information</v-icon>
                </template>
                <span>{{ $t('time_tracking.general.sprint_finished') }}</span>
              </v-tooltip>
            </div>
            <div style="display: inline-block;" :style="{'max-width': $vuetify.breakpoint.xsOnly ? '100px' : 'unset'}">
              {{item.project_title}}<br>
              {{item.sprint_name}}
            </div>
          </td>
          <td
            v-for="header in headers"
            :key="`${header.text}_${item.sprint_id}`"
            :style="{'background': getBackgroundColor(header)}"
            :class="{'border-l': $vuetify.breakpoint.mdAndDown, 'clickable': !selectedUser}"
            class="text-center background-transition"
            @click="openForm(header.text, item.sprint_id)"
          >
            <v-tooltip
              v-if="item.time_spent[header.text] && (item.time_spent[header.text].time_spent || item.time_spent[header.text].comment)"
              :disabled="!item.time_spent[header.text].comment || $vuetify.breakpoint.xsOnly"
              transition="none"
              bottom
            >
              <template v-slot:activator="{ on }">
                <div v-on="on" class="day-cell-content">
                  {{ item.time_spent[header.text].time_spent }}
                </div>
              </template>
              <span class="preserve-whitespace">
                {{item.time_spent[header.text].comment }}
              </span>
            </v-tooltip>
          </td>
        </tr>
      </tbody>

      <tfoot>
        <tr style="background-color: #F6F6F6">
          <td class="month-column border-y" style="white-space: nowrap">
            {{ aggregateRow.project_title }}<br>
            {{ aggregateRow.sprint_name }}
          </td>
          <td
            v-for="header in headers"
            :key="header.text"
            :class="{'border-l': $vuetify.breakpoint.mdAndDown, 'clickable': !selectedUser}"
            :style="{'background': getBackgroundColor(header)}"
            class="text-center background-transition month-column border-y"
            @click="openForm(header.text, null)"
          >
            <template v-if="aggregateRow.time_spent[header.text]">
              {{ aggregateRow.time_spent[header.text] | round(2) }}
            </template>
          </td>
        </tr>
      </tfoot>
    </v-simple-table>

    <v-alert
      v-else-if="!isLoading"
      class="ma-3"
      type="error"
      max-width="500"
      outlined
    >
      {{ $t('no_active_sprints') }}
    </v-alert>

    <v-dialog
      v-model="isTimeTrackingFormOpen"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      transition="slide-y-reverse-transition"
      max-width="800"
      persistent
      scrollable
    >
      <TimeTrackingForm
        :can-edit="!selectedUser"
        :dialog="isTimeTrackingFormOpen"
        :entries="items"
        :date="selectedDate"
        :user-id="selectedUser ? selectedUser.id : $store.getters.currentUser.id"
        :sprint-id="selectedSprintId"
        @create="addEntriesToTable"
        @cancel="isTimeTrackingFormOpen = false"
      />
    </v-dialog>
  </BaseLoadingOverlay>
</template>

<script>
import { format, startOfMonth, endOfMonth, addDays, addMonths, subMonths, addWeeks, subWeeks, startOfISOWeek, endOfISOWeek } from 'date-fns';
import { lt } from 'date-fns/locale';
import listMixin from '../mixins/list-mixin';
import BaseAutocomplete from '../../base/BaseAutocomplete';
import userService from '../../../api/user-service';
import BaseLoadingOverlay from '../../base/BaseLoadingOverlay';
import TimeTrackingForm from '../../forms/TimeTrackingForm';
import timeTrackingService from '../../../api/time-tracking-service';

export default {
  name: 'TimeTrackingTable',

  components: {
    TimeTrackingForm,
    BaseLoadingOverlay,
    BaseAutocomplete,
  },

  mixins: [listMixin],

  data() {
    return {
      currentMonth: '',
      userSearchFunction: userService.search,
      headers: [],
      dateFrom: format(new Date(), 'yyyy-MM-dd'),
      dateTo: '',
      today: '',
      currentMonthMiddle: '', // for displaying/hiding return to current month buttons
      currentAggregateRow: {
        project_id: 0,
        project_title: `${this.$t('total')}:`,
        sprint_id: 0,
        sprint_name: '',
        time_spent: {},
      },
      selectedUser: null,
      items: [],
      isLoading: true,
      isTimeTrackingFormOpen: false,
      selectedDate: '',
      selectedSprintId: null,
    };
  },

  created() {
    this.currentMonthMiddle = format(addDays(startOfMonth(new Date()), 15), 'yyyy-MM-dd');
    this.today = format(new Date(), 'yyyy-MM-dd');
    this.getCurrentWorkMonth();
  },

  computed: {
    aggregateRow() {
      const aggregateRow = {
        project_id: 0,
        project_title: `${this.$t('total')}:`,
        sprint_id: 0,
        sprint_name: '',
        time_spent: {},
      };

      let date;
      const endDate = new Date(this.dateTo);
      const sprintCount = this.items.length;
      for (let d = new Date(this.dateFrom); d <= endDate; d.setDate(d.getDate() + 1)) {
        date = format(d, 'yyyy-MM-dd');
        aggregateRow.time_spent[date] = null;
        for (let i = 0; i < sprintCount; i++) {
          if (this.items[i].time_spent[date]) {
            this.items[i].time_spent[date].time_spent = +this.items[i].time_spent[date].time_spent;
            aggregateRow.time_spent[date] += this.items[i].time_spent[date].time_spent;
          }
        }
      }
      return aggregateRow;
    },
  },

  methods: {
    addEntriesToTable(savedEntries) {
      for (let i = 0; i < savedEntries.length; i++) {
        const savedEntry = savedEntries[i];
        for (let j = 0; j < this.items.length; j++) {
          const oldEntry = this.items[j];
          if (oldEntry.sprint_id === savedEntry.sprint_id) {
            this.$set(oldEntry.time_spent, savedEntry.date, savedEntry);
            break;
          }
        }
      }
    },

    getCurrentWorkMonth() {
      let dateFrom;
      let dateTo;
      if (this.$vuetify.breakpoint.mdAndDown) {
        dateFrom = startOfISOWeek(new Date());
        dateTo = endOfISOWeek(new Date(dateFrom));
      } else {
        dateFrom = startOfMonth(new Date());
        dateTo = endOfMonth(new Date());
        if (format(dateFrom, 'i') !== '1') {
          dateFrom = startOfISOWeek(dateFrom);
        }
        if (format(dateTo, 'i') !== '7') {
          dateTo = endOfISOWeek(dateTo);
        }
      }
      dateFrom = format(dateFrom, 'yyyy-MM-dd');
      dateTo = format(dateTo, 'yyyy-MM-dd');
      if (new Date() < dateFrom) {
        this.getPreviousWorkMonth();
      } else {
        this.getTimeTrackingEntries(dateFrom, dateTo);
      }
    },

    getPreviousWorkMonth() {
      let dateFrom = this.dateFrom ? new Date(this.dateFrom) : new Date();
      let dateTo;
      if (this.$vuetify.breakpoint.mdAndDown) {
        dateFrom = startOfISOWeek(subWeeks(dateFrom, 1));
        dateTo = endOfISOWeek(dateFrom);
      } else {
        const currentDateFrom = addDays(dateFrom, 15);
        dateFrom = startOfMonth(subMonths(currentDateFrom, 1));
        dateTo = endOfMonth(subMonths(currentDateFrom, 1));
        if (format(dateFrom, 'i') !== '1') {
          dateFrom = startOfISOWeek(dateFrom);
        }
        if (format(dateFrom, 'i') !== '7') {
          dateTo = endOfISOWeek(dateTo);
        }
      }
      dateFrom = format(dateFrom, 'yyyy-MM-dd');
      dateTo = format(dateTo, 'yyyy-MM-dd');
      this.getTimeTrackingEntries(dateFrom, dateTo);
    },

    getNextWorkMonth() {
      let dateFrom = this.dateFrom ? new Date(this.dateFrom) : new Date();
      let dateTo;
      if (this.$vuetify.breakpoint.mdAndDown) {
        dateFrom = startOfISOWeek(addWeeks(dateFrom, 1));
        dateTo = endOfISOWeek(dateFrom);
      } else {
        const currentDateFrom = addDays(dateFrom, 15);
        dateFrom = startOfMonth(addMonths(currentDateFrom, 1));
        dateTo = endOfMonth(addMonths(currentDateFrom, 1));
        if (format(dateFrom, 'i') !== '1') {
          dateFrom = startOfISOWeek(dateFrom);
        }
        if (format(dateFrom, 'i') !== '7') {
          dateTo = endOfISOWeek(dateTo);
        }
      }
      dateFrom = format(dateFrom, 'yyyy-MM-dd');
      dateTo = format(dateTo, 'yyyy-MM-dd');
      this.getTimeTrackingEntries(dateFrom, dateTo);
    },

    formHeaders() {
      let date;
      let day;
      let month;
      let weekdayNo;
      let weekdayStr;
      const endDate = new Date(this.dateTo);
      this.headers = [];
      for (let d = new Date(this.dateFrom); d <= endDate; d.setDate(d.getDate() + 1)) {
        date = format(d, 'yyyy-MM-dd');
        day = format(d, 'dd');
        month = format(d, 'MM');
        weekdayNo = format(d, 'i');
        weekdayStr = format(d, 'iii', { locale: lt });
        this.headers.push({
          text: date,
          day,
          month,
          weekdayNo,
          weekdayStr,
          sortable: false,
        });
      }
      this.currentMonth = format(addDays(new Date(this.dateFrom), 15), 'yyyy MM');
    },

    setUser(user) {
      this.selectedUser = user;
      this.items = [];
      this.getTimeTrackingEntries(this.dateFrom, this.dateTo);
    },

    getTimeTrackingEntries(dateFrom, dateTo) {
      const params = {
        date_from: dateFrom,
        date_to: dateTo,
      };
      if (this.selectedUser) {
        params.user_id = this.selectedUser.id;
      }

      this.isLoading = true;
      timeTrackingService.getEntries(params).then((response) => {
        let date;
        let timeSpent;
        let startDate;
        let endDate;
        const items = [];
        this.dateFrom = dateFrom;
        this.dateTo = dateTo;
        this.formHeaders();
        response.data.forEach((project) => {
          project.sprints.forEach((sprint) => {
            timeSpent = {};
            startDate = new Date(this.dateFrom);
            endDate = new Date(this.dateTo);
            for (let d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {
              date = format(new Date(d), 'yyyy-MM-dd');
              timeSpent[date] = sprint.time_spent[date] ? sprint.time_spent[date] : null;
            }
            items.push({
              project_id: project.project_id,
              project_title: project.project_title,
              sprint_id: sprint.sprint_id,
              sprint_name: sprint.sprint_name,
              sprint_status: sprint.sprint_status,
              time_spent: timeSpent,
            });
          });
        });
        this.items = items;
      }).finally(() => {
        this.isLoading = false;
      });
    },

    getBackgroundColor(header) {
      if (header.text === this.today) {
        return '#00FFCE';
      } if (header.weekdayNo === '0' || header.weekdayNo === '6') {
        return '#F6F6F6';
      }
      return '';
    },

    openForm(day, sprintId) {
      this.selectedDate = day;
      this.selectedSprintId = sprintId;
      this.isTimeTrackingFormOpen = true;
    },
  },
};
</script>

<style scoped>
  td, th {
    padding: 0 4px !important;
  }

  .closed-sprint-icon {
    position: relative;
    display: inline-block;
    margin-right: 4px;
  }

  .sprint-title {
    padding: 0 4px !important;
    display: flex;
    align-items: center;
    height: auto;
    min-height: 48px;
  }

  .day-cell-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
</style>
