<template>
  <v-form @submit.prevent="onSubmit">
    <v-card>
      <v-card-title>
        {{ formTitle }}
      </v-card-title>

      <v-card-text>
        <v-row
          v-for="(entry, index) in formItems"
          :key="entry.sprint_id"
          dense
        >
          <v-col cols="3" sm="2" class="d-flex align-center">
            <v-text-field
              v-model="entry.time_spent"
              :error-messages="errors[`data.${index}.time_spent`]"
              :disabled="!canEdit"
              :label="$t('hours_spent')"
              type="number"
              lang="lt"
              @blur="formMixin_clearErrors('name')"
            />
          </v-col>
          <v-col cols="9" sm="10">
            <v-textarea
              v-model="entry.comment"
              :error-messages="errors[`data.${index}.comment`]"
              :label="`${entry.sprint_name} (${entry.project_title})`"
              :disabled="!canEdit"
              rows="1"
              auto-grow
              @blur="formMixin_clearErrors('comment')"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions v-if="canEdit">
        <v-spacer/>

        <v-btn
          color="primary"
          text
          @click.native="$emit('cancel')"
        >
          {{ $t('cancel') }}
        </v-btn>

        <v-btn
          :disabled="isRequestPending"
          :loading="isRequestPending"
          type="submit"
          color="primary"
          text
        >
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
      <v-card-actions v-else>
        <v-spacer/>

        <v-btn
          color="primary"
          text
          @click.native="$emit('cancel')"
        >
          {{ $t('close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { format } from 'date-fns';
import { lt } from 'date-fns/locale';
import crudMixin from '../../mixins/crud-mixin';
import dialogMixin from '../../mixins/dialog-mixin';
import formMixin from '../../mixins/form-mixin';
import timeTrackingService from '../../api/time-tracking-service';

export default {
  name: 'TimeTrackingForm',

  mixins: [crudMixin, dialogMixin, formMixin],

  props: {
    canEdit: Boolean,
    date: {
      type: String,
      required: true,
    },
    entries: {
      type: Array,
      required: true,
    },
    userId: {
      type: Number,
      required: true,
    },
    sprintId: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      errors: {},
      isRequestPending: false,
      formItems: [],
    };
  },

  computed: {
    weekday() {
      return format(new Date(this.date), 'iiii', { locale: lt });
    },

    formTitle() {
      return `${this.date} (${this.weekday}) ${this.$t('completed_tasks').toLowerCase()}`;
    },
  },

  methods: {
    onDialogOpen() {
      this.errors = {};
      let { entries } = this;
      if (this.sprintId) {
        entries = entries.filter(e => e.sprint_id === this.sprintId);
      }
      this.formItems = entries.map((entry) => {
        const formEntry = {
          ...entry,
          comment: entry.time_spent[this.date] ? entry.time_spent[this.date].comment : null,
          time_spent: entry.time_spent[this.date] ? entry.time_spent[this.date].time_spent : '',
          user_id: this.userId,
          date: this.date,
        };
        if (!formEntry.time_spent) {
          // otherwise when editing, time_spent values will be transformed to 0
          // which would be inconsistent
          delete formEntry.time_spent;
        }
        return formEntry;
      });
    },

    onSubmit() {
      const payload = {
        user_id: this.userId,
        data: this.formItems.map(item => ({
          ...item,
          // backend expects time_spent to exist at all times
          time_spent: +item.time_spent > 0 ? item.time_spent : null,
        })),
      };
      this.crudMixin_create(timeTrackingService.createMultiple, '', payload, this.$t('saved'));
    },
  },
};
</script>
