<template>
  <div class="wrapper">
    <slot/>
    <div v-if="loading" class="overlay">
      <v-progress-circular
        color="primary"
        size="70"
        width="7"
        indeterminate
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseLoadingOverlay',

  props: {
    loading: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style scoped>
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255,255,255,0.7);
    min-height: 200px;
    z-index: 50;
  }

  .wrapper {
    height: 100%;
    width: 100%;
    position: relative;
  }
</style>
