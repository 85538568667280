import { mapErrorsToInputs } from '../util/forms';
import { removeItemById, updateItemById } from '../util/array';
import eventBus, {
  CONFIRM_DIALOG_CLOSED, OPEN_CONFIRM_DIALOG, OPEN_SNACKBAR,
} from '../util/event-bus';
import { capitalizeFirstLetter, pascalToSnakeCase } from '../util/strings';

/**
 * For generic create, read, update, delete operations.
 * apiCall param in methods here should be a function, that calls backend and returns a promise.
 * model param should be a singular string, f.e.: 'user'
 */
const crudMixin = {
  methods: {
    async crudMixin_getPage(apiCall, model, pageNo, queryParams) {
      let res;
      try {
        res = await apiCall(pageNo, queryParams);

        if (this[`${model}Array`]) {
          this[`${model}Array`] = res.data.data;
        }
        // laravel pagination structure is sometimes wrapped inside a meta object 🤔
        if (res.data.meta) {
          this[`${model}Pagination`] = {
            rowsPerPage: res.data.meta.per_page,
            totalItems: res.data.meta.total,
            page: res.data.meta.current_page,
          };
        } else {
          this[`${model}Pagination`] = {
            rowsPerPage: res.data.per_page,
            totalItems: res.data.total,
            page: res.data.current_page,
          };
        }

        await this.$nextTick();
        await this.$vuetify.goTo(0, {
          duration: 900,
          easing: 'easeOutQuint',
        });
      } catch (e) {
        console.error(e);
        res = e?.response;
        eventBus.$emit(OPEN_SNACKBAR, `${this.$t('data_fetch_fail')}. ${this.$t('try_reloading')}.`);
      }

      if (this.isDataLoading) {
        this.isDataLoading = false;
      }
      return res;
    },

    async crudMixin_getAll(apiCall, model, queryParams) {
      let res;
      try {
        res = await apiCall(queryParams);

        if (this[`${model}Array`]) {
          if (res.data.data) {
            this[`${model}Array`] = res.data.data;
          } else {
            this[`${model}Array`] = res.data;
          }
        }
      } catch (e) {
        console.error(e);
        res = e?.response;
        eventBus.$emit(OPEN_SNACKBAR, `${this.$t('data_fetch_fail')}. ${this.$t('try_reloading')}.`);
      }

      if (this.isDataLoading) {
        this.isDataLoading = false;
      }
      return res;
    },

    async crudMixin_getById(apiCall, model, id) {
      let res;

      try {
        res = await apiCall(id);
        if (this[model]) {
          if (res.data.data) {
            this[model] = res.data.data;
          } else {
            this[model] = res.data;
          }
        }
        if (this[`${model}Array`]) {
          this[`${model}Array`] = updateItemById(this[`${model}Array`], this[model]);
        }
      } catch (e) {
        console.error(e);
        res = e?.response;
        eventBus.$emit(OPEN_SNACKBAR, `${this.$t('data_fetch_fail')}. ${this.$t('try_reloading')}.`);
      }

      if (this.isDataLoading) {
        this.isDataLoading = false;
      }

      return res;
    },

    async crudMixin_scrollToFirstFormError() {
      await this.$nextTick();
      const el = document.querySelector('.v-input.error--text');
      if (el) {
        el.scrollIntoView();
      }
    },

    async crudMixin_create(apiCall, model, payload, customSuccessMsg, errorMsg) {
      let res;
      if (this.isRequestPending === false) {
        this.isRequestPending = true;
      }

      try {
        res = await apiCall(payload);
        this.$emit('create', res.data?.data ? res.data.data : res.data);
        this.$emit('cancel');
        const msg = typeof customSuccessMsg === 'string'
          ? customSuccessMsg
          : this.$t(`${pascalToSnakeCase(model)}_created`);
        if (msg) {
          eventBus.$emit(OPEN_SNACKBAR, msg);
        }
      } catch (e) {
        console.error(e);
        res = e?.response;
        this.errors = mapErrorsToInputs(e);
        this.crudMixin_scrollToFirstFormError();
        if (errorMsg) {
          eventBus.$emit(OPEN_SNACKBAR, errorMsg);
        }
      }

      if (this.isRequestPending) {
        this.isRequestPending = false;
      }
      return res;
    },

    async crudMixin_update(apiCall, model, payload, customSuccessMsg) {
      let res;
      if (this.isRequestPending === false) {
        this.isRequestPending = true;
      }

      try {
        res = await apiCall(payload);
        this.$emit('update', res.data?.data ? res.data.data : res.data);
        this.$emit('cancel');
        const msg = typeof customSuccessMsg === 'string'
          ? customSuccessMsg
          : this.$t(`${pascalToSnakeCase(model)}_updated`);
        if (msg) {
          eventBus.$emit(OPEN_SNACKBAR, msg);
        }
      } catch (e) {
        console.error(e);
        res = e?.response;
        this.errors = mapErrorsToInputs(e);
        this.crudMixin_scrollToFirstFormError();
      }

      if (this.isRequestPending) {
        this.isRequestPending = false;
      }
      return res;
    },

    // Service should be an object with a model field (e.g. model: 'user')
    // and implemented update and create functions which make API calls and return promises
    async crudMixin_createOrUpdate(service, payload, customCreateMsg, customUpdateMsg) {
      let res;
      if (payload.id) {
        res = await this.crudMixin_update(service.update, service.model, payload, customUpdateMsg);
      } else {
        res = await this.crudMixin_create(service.create, service.model, payload, customCreateMsg);
      }
      return res;
    },

    async crudMixin_delete(apiCall, model, payload, customSuccessMsg) {
      eventBus.$emit(OPEN_CONFIRM_DIALOG, {
        title: this.$t('confirm_entry_delete'),
      });
      return new Promise((resolve, reject) => {
        eventBus.$on(CONFIRM_DIALOG_CLOSED, async (confirmed) => {
          if (confirmed) {
            try {
              const res = await apiCall(payload);
              if (this[`${model}Array`]) {
                this[`${model}Array`] = removeItemById(this[`${model}Array`], payload.id);
              }
              const msg = typeof customSuccessMsg === 'string'
                ? customSuccessMsg
                : this.$t(`${pascalToSnakeCase(model)}_deleted`);
              if (msg) {
                eventBus.$emit(OPEN_SNACKBAR, msg);
              }
              resolve(res);
            } catch (e) {
              console.error(e);
              eventBus.$emit(OPEN_SNACKBAR, this.$t('delete_failed'));
              reject(e);
            }
          }
          eventBus.$off(CONFIRM_DIALOG_CLOSED);
        });
      });
    },

    crudMixin_openForm(model, payload) {
      if (this[`${model}FormItem`]) {
        this[`${model}FormItem`] = payload ? JSON.parse(JSON.stringify(payload)) : {};
      }
      if (this[`is${capitalizeFirstLetter(model)}FormOpen`] === false) {
        this[`is${capitalizeFirstLetter(model)}FormOpen`] = true;
      }
    },

    crudMixin_created(model, payload, pushToFront) {
      if (this[`${model}Array`]) {
        if (pushToFront) {
          this[`${model}Array`].unshift(payload);
        } else {
          this[`${model}Array`].push(payload);
        }
      }
      if (this[`${model}Pagination`]) {
        this[`${model}Pagination`].totalItems += 1;
      }
    },

    crudMixin_updated(model, payload) {
      if (this[`${model}Array`]) {
        this[`${model}Array`] = updateItemById(this[`${model}Array`], payload);
      }
    },
  },
};

export default crudMixin;
