// Used in projects components that were moved from intranet without refactoring.
// Not recommended to use in new code.
// Should be refactored along with the entire projects module.

import eventBus, { CONFIRM_DIALOG_CLOSED, OPEN_CONFIRM_DIALOG } from '../../../util/event-bus';

export default {
  data() {
    return {
      dialog: false,
      loading: true,
      editedItem: {},
      editedIndex: -1,
      pagination: {
        page: 1,
        rowsPerPage: 50,
        totalItems: 0,
      },
      filterParams: '',
    };
  },

  watch: {
    dialog(val) {
      if (!val) {
        this.close();
      }
    },

    pagination: {
      handler(oldVal, newVal) {
        if (!this.loading && oldVal.page !== newVal.page) {
          this.getPaginatedItems(this.filterParams, this.pagination.page);
          this.$vuetify.goTo(0, {
            duration: 900,
            easing: 'easeOutQuint',
          });
        }
      },
      deep: true,
    },
  },

  methods: {
    getItems(filterParams) {
      this.$http.get(`${this.indexRoute}?${filterParams}`).then((response) => {
        if (this.storeAction) {
          this.$store.dispatch(this.storeAction, response.data);
        } else {
          this.items = response.data;
        }
        this.loading = false;
        this.$emit('finishedLoading');
      }).catch(() => {
        this.loading = false;
        this.$emit('finishedLoading');
      });
    },

    getPaginatedItems(filterParams, page) {
      let endpoint;
      if (this.indexRoute.indexOf('?') > -1) {
        endpoint = `${this.indexRoute}&page=${page}&${filterParams}`;
      } else {
        endpoint = `${this.indexRoute}?page=${page}&${filterParams}`;
      }
      this.$http.get(endpoint).then((response) => {
        if (response.data.meta) {
          this.pagination.rowsPerPage = response.data.meta.per_page;
          this.pagination.totalItems = response.data.meta.total;
          this.pagination.page = response.data.meta.current_page;
        } else {
          this.pagination.rowsPerPage = response.data.per_page;
          this.pagination.totalItems = response.data.total;
          this.pagination.page = response.data.current_page;
        }
        if (this.storeAction) {
          this.$store.dispatch(this.storeAction, response.data.data);
        } else {
          this.items = response.data.data;
        }
        this.loading = false;
        this.$emit('finishedLoading');
      }).catch(() => {
        this.loading = false;
        this.$emit('finishedLoading');
      });
    },

    filterUpdated(filterParams) {
      this.filterParams = filterParams;
      if (this.pagination.page) {
        this.getPaginatedItems(this.filterParams, 1);
      } else {
        this.getItems(this.filterParams);
      }
    },

    createItem(newItem) {
      if (this.fab) { // when used from speed dial on mobile, close it on click
        this.fab = false;
      }
      this.editedIndex = -1;
      this.editedItem = JSON.parse(JSON.stringify(newItem));
      this.dialog = true;
    },

    createItemSpeedDial(newItem) {
      if ((this.$store.getters.isTouchDevice && this.fab) || !this.$store.getters.isTouchDevice) {
        this.editedIndex = -1;
        this.editedItem = JSON.parse(JSON.stringify(newItem));
        this.dialog = true;
      }
    },

    editItem(item, index, permissions) {
      if (!permissions || (permissions && this.$can(permissions))) {
        this.editedItem = JSON.parse(JSON.stringify(item));
        this.editedIndex = index;
        this.dialog = true;
      }
    },

    dispatchEditAction(item, index) {
      this.$store.dispatch(this.editAction, {
        item,
        index,
      });
    },

    deleteItem(id, index, route, permissions) {
      if (!permissions || (permissions && this.$can(permissions))) {
        eventBus.$emit(OPEN_CONFIRM_DIALOG, this.$t('confirmations.remove_entry'));
        this.$eventBus.$on(CONFIRM_DIALOG_CLOSED, (confirmed) => {
          if (confirmed) {
            this.$http.delete(`${route}${id}`).then(() => {
              if (this.deleteAction) {
                this.$store.dispatch(this.deleteAction, index);
              } else {
                this.items.splice(index, 1);
              }
            });
          }
          this.$eventBus.$off(CONFIRM_DIALOG_CLOSED);
        });
      }
    },

    itemCreated(item, bringToFront) {
      if (bringToFront) {
        this.items.unshift(item);
      } else {
        this.items.push(item);
      }
      this.dialog = false;
    },

    itemUpdated(item) {
      this.$set(this.items, this.editedIndex, item);
      this.dialog = false;
    },

    close(e) {
      this.dialog = false;
    },

    downloadFile(url, item, nameField) {
      this.$http({
        url,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', item[nameField]);
        document.body.appendChild(link);
        link.click();
      });
    },

    toggleListCheckbox(item, index, field, route, method = 'put') {
      this.$set(item, field, !item[field]);
      this.$http[method](`${route}${item.id}`, item).then((response) => {
        if (this.updateAction) {
          this.$store.dispatch(this.updateAction, {
            index,
            item: response.data,
          });
        }
      }).catch(() => {
        this.$set(item, field, !item[field]);
      });
    },
  },
};
